import React, { useEffect, useState } from "react";
import { generateClient } from 'aws-amplify/api';
import { motion } from 'framer-motion';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Box,
    Container,
    Paper,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { GamepadIcon, DollarSignIcon, UserIcon, Contact } from 'lucide-react';
import Swal from 'sweetalert2';
import { PaymentHnadler, createPaymentLink } from "./PaymentHandler";

import Footer from "./Footer"
import NewNavbar from "./Navbar";

import ProfessionalNavbar from './ProfessionalNavbar';
import { getAuraStoreItems } from '../graphql/queries';

// Styled Components
const GamerPaper = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(145deg, #1e1e2f, #16162a)',
    borderRadius: '15px',
    padding: theme.spacing(3),
    color: '#ffffff',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(255,255,255,0.1)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
    }
}));

const GameDiamondButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)',
    color: '#ffffff',
    padding: theme.spacing(1.5, 3),
    borderRadius: '25px',
    fontWeight: 'bold',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        background: 'linear-gradient(45deg, #2575fc 0%, #6a11cb 100%)',
        transform: 'translateY(-3px)',
        boxShadow: '0 4px 17px rgba(0, 0, 0, 0.35)',
    }
}));

const DiamondPackContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
}));

const DiamondPack = styled(motion.div)(({ theme, selected }) => ({
    width: '120px',
    padding: theme.spacing(2),
    borderRadius: '15px',
    textAlign: 'center',
    background: selected
        ? 'linear-gradient(145deg, #6a11cb, #2575fc)'
        : 'linear-gradient(145deg, #2c2c3e, #1f1f2e)',
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    transitionDuration: '1.2s'
}));
const GameImage = styled('img')({
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    }
});




const client = generateClient();

export default function Mlbb(params) {

    const [stepOneError, setStepOneError] = useState(false);
    const [stepOneErrorMessage, setStepOneErrorMessage] = useState("");
    const [stepTwoError, setStepTwoError] = useState(false);
    const [recharging, setRecharging] = useState(false);
    const [rechargeWarning, setRechargeWarning] = useState(false);
    const [myYokcashDict, setYokcashDict] = useState([]);
    const [myAmountDict, setMyAmountDict] = useState([]);
    const [isLive, setIsLive] = useState("load");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailID, setEmailID] = useState("");
    const [userInfoSaved, setUserInfoSaved] = useState(false);
    const [showID, setShowID] = useState(false);
    const [userid, setUserID] = useState("");
    const [serverid, setServerID] = useState("");
    const [selectedValue, setSelectedValue] = useState();
    const [amount, setAmount] = useState();
    const [ign, setIgn] = useState('');
    const [selectedPack, setSelectedPack] = useState(null);

    useEffect(() => {
        getPack();
        try {
            setEmailID(localStorage.getItem('emailid')?localStorage.getItem('emailid'):"");
            setPhoneNumber(localStorage.getItem('phoneno')?localStorage.getItem('phoneno'):"")
        } catch (error) {
            console.log(error)
        }

    }, [])

    const handleChange = (event) => {
        setSelectedValue(JSON.parse(event.target.value)['item']);
        setAmount(JSON.parse(event.target.value)['price'])
    };

    const handlePackSelect = (event) => {
        setSelectedPack(event.target.value);
        console.log(event.target.value)
    }

    async function getPack() {

        const apiData = await client.graphql({
            query: getAuraStoreItems,
            variables: {
                itemname: 'store-diamonds',
            },
        });
        // console.log(JSON.parse(apiData["data"]['getAuraStoreItems']['storeitems']))
        if (apiData["data"]['getAuraStoreItems']) {
            setYokcashDict(JSON.parse(apiData["data"]['getAuraStoreItems']['ef1'])["yokcash"])
            setMyAmountDict(JSON.parse(apiData["data"]['getAuraStoreItems']['storeitems']))
            setIsLive(apiData["data"]['getAuraStoreItems']['isactive'])
        }

    }

    const getIGN = () => {
        if (userid === "" || userid === "") {
            Swal.fire({
                icon: "error",
                title: "Please enter user id and zone id!",
            });
        } else {
            setIgn("");
            // setLoading(true);
            var xhr = new XMLHttpRequest();
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    // console.log(this.responseText);
                    let recjson = JSON.parse(this.responseText);
                    if (recjson["body"]) {
                        setIgn(decodeURIComponent(recjson["body"]).slice(1, -1));
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "User with this id not found",
                        });
                    }
                }
            });
            xhr.open(
                "GET",
                `https://chp701oq1m.execute-api.ap-south-1.amazonaws.com/v1/get?userid=${userid}&zoneid=${serverid}`
            );
            xhr.send();
        }
    }

    const handlePayment = () => {
        var validRegex = /\S+@\S+\.\S+/;
        var phoneno = /^\d{10}$/;

        if (userid === "" || serverid === "") {
            Swal.fire({
                icon: 'warning',
                text: 'Please enter your user id and zone id',
            })

        }
        else if (amount === undefined) {
            Swal.fire({
                icon: 'warning',
                text: 'Please select diamond pack',
            })
        }
        else if (ign === "") {
            Swal.fire({
                icon: 'error',
                text: 'Please search your IGN',
            })
        }
        // else if (phoneNumber.match(phoneno)) {
        //     Swal.fire({
        //       icon: 'error',
        //       text: 'Please enter proper mobile number'
        //     })
        //   }
        else if (emailID === "") {
            Swal.fire({
                icon: 'error',
                text: 'Please enter proper email address'
            })
        }
        else if (phoneNumber === "") {
            Swal.fire({
                icon: 'error',
                text: 'Please enter proper phone number'
            })
        }
        else if (!emailID.match(validRegex)) {
            Swal.fire({
                icon: 'error',
                text: 'Please enter proper email address'
            })
        }
        else {
            let props = {
                userID: userid,
                serverID: serverid,
                amount: amount,
                selectedPack: selectedPack,
                emailID: emailID,
                phoneNumber: phoneNumber,
                ordertype: selectedPack.startsWith("yokcash")?'aura-yokcash':'aura'

            }
            console.log("Final Json ", JSON.stringify(props))
            try {
                setRecharging(true);
                PaymentHnadler(props).then(
                    orderJson => {
                        createPaymentLink(props, orderJson)
                    }
                )
                setRecharging(false);
            }
            catch (e) {
                console.log(e);
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong! please try again after sometime or contact admin',
                })
                setRecharging(false);
            }
        }
    }

    return (
        <>

            <Footer />
            <Container maxWidth="lg" sx={{mb:'3rem'}}>
                <GameImage src='/mlbb.jpg' alt="mlbb" />
                <Box sx={{
                    background: 'linear-gradient(to right, #1a1a2e, #16213e)',
                    minHeight: '100vh',
                    pt: 4,
                    pb: 4
                }}>
                    <Typography
                        variant="h3"
                        align="center"
                        gutterBottom
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            textShadow: '0 0 10px rgba(37, 117, 252, 0.5)'
                        }}
                    >
                        Mobile Legends Recharge
                    </Typography>

                    {isLive === "true"?
                    <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <GamerPaper>
                                <Typography variant="h5" gutterBottom>
                                    <UserIcon style={{ marginRight: 10 }} /> Contact Information
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="Email ID"
                                    variant="outlined"
                                    sx={{
                                        mb: 2,
                                        '& .MuiInputBase-input': { color: 'white' }, // Text color
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Outline color
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Hover state
                                        '& .MuiInputLabel-root': { color: 'white' }, // Label color
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                    }}
                                    value={emailID}
                                    onChange={e => setEmailID(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    variant="outlined"
                                    value={phoneNumber}
                                    sx={{
                                        '& .MuiInputBase-input': { color: 'white' }, // Text color
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Outline color
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Hover state
                                        '& .MuiInputLabel-root': { color: 'white' }, // Label color
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                    }}
                                    onChange={e => {
                                        const input = e.target.value;
                                        const filteredInput = input.replace(/[^0-9]/g, ''); // Allow only numeric values
                                        if (filteredInput.length <= 10) {
                                          // Restrict to max 10 digits for standard phone numbers
                                          setPhoneNumber(filteredInput);
                                        }
                                      }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        var validRegex = /\S+@\S+\.\S+/;
                                        if (!emailID.match(validRegex)) {
                                            Swal.fire({
                                                icon: 'error',
                                                text: 'Please enter proper email address'
                                            })
                                        }
                                        else {
                                            Swal.fire({
                                                icon: 'success',
                                                text: 'Information saved.',
                                            })
                                        }
                                    }}
                                    sx={{ mt: 2, width: '100%' }}
                                >
                                    Save
                                </Button>
                            </GamerPaper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GamerPaper>
                                <Typography variant="h5" gutterBottom>
                                    <GamepadIcon style={{ marginRight: 10 }} /> Player Information
                                </Typography>
                                <TextField
                                    fullWidth
                                    label="User ID"
                                    variant="outlined"
                                    sx={{
                                        mb: 2,
                                        '& .MuiInputBase-input': { color: 'white' }, // Text color
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Outline color
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Hover state
                                        '& .MuiInputLabel-root': { color: 'white' }, // Label color
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                    }}
                                    value={userid}
                                    onChange={e => setUserID(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label="Server ID"
                                    variant="outlined"
                                    value={serverid}
                                    sx={{
                                        '& .MuiInputBase-input': { color: 'white' }, // Text color
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Outline color
                                        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, // Hover state
                                        '& .MuiInputLabel-root': { color: 'white' }, // Label color
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                    }}
                                    onChange={e => setServerID(e.target.value)}
                                />
                                <h4 id="ign_id" style={{textAlign:'center', margin:'0px', marginTop:'12px'}}>{ign}</h4>
                                <Button
                                    variant="contained"
                                    onClick={getIGN}
                                    sx={{ mt: 2, width: '100%' }}
                                >
                                    Verify Player
                                </Button>
                            </GamerPaper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <GamerPaper>
                                <Typography variant="h5" gutterBottom sx={{textAlign:'center'}}>
                                    Diamond Packages
                                </Typography>
                                <DiamondPackContainer>
                                    {myYokcashDict.map((element) => (
                                            element.isactive === "true" && (
                                                <DiamondPack
                                                    key={element.name}
                                                    selected={selectedPack === "yokcash-"+element.name}
                                                    onClick={() => {
                                                        setSelectedPack("yokcash-"+element.name);
                                                        setAmount(element.price);
                                                    }}
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 0.95 }}
                                                >
                                                    <Typography >{element.name}</Typography>
                                                    <img
                                                        src={element.img.startsWith("https")?`${element.img}`:`https://www.auraofficialstore.com/${element.img}`}
                                                        alt="diamonds"
                                                        style={{ maxWidth: '100px', margin: '10px 0' }}
                                                    />
                                                    <Typography variant="body1">
                                                        <del style={{ color: 'red', marginRight: 10 }}>₹{element.mrp}</del>
                                                        ₹{element.price}
                                                    </Typography>
                                                </DiamondPack>
                                            )
                                        ))}
                                    {myAmountDict.map((element) => (
                                        element.isactive === "true" && (
                                            <DiamondPack
                                                key={element.name}
                                                selected={selectedPack === element.name}
                                                onClick={() => {
                                                    setSelectedPack(element.name);
                                                    setAmount(element.price);
                                                }}
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                <Typography >{element.name}</Typography>
                                                <img
                                                   src={element.img.startsWith("https")?`${element.img}`:`https://www.auraofficialstore.com/${element.img}`}
                                                    alt="diamonds"
                                                    style={{ maxWidth: '100px', margin: '10px 0' }}
                                                />
                                                <Typography variant="body1">
                                                    <del style={{ color: 'red', marginRight: 10 }}>₹{element.mrp}</del>
                                                    ₹{element.price}
                                                </Typography>
                                            </DiamondPack>
                                        )
                                    ))}
                                </DiamondPackContainer>
                            </GamerPaper>
                        </Grid>
                    </Grid>

                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                        {recharging ? (
                            <LinearProgress color="primary" />
                        ) : (
                            <GameDiamondButton
                                variant="contained"
                                onClick={handlePayment}
                                size="large"
                            >
                                Recharge Diamonds
                            </GameDiamondButton>
                        )}
                    </Box>
                    </>:
                    <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <GamerPaper>
                                <Typography variant="h5" gutterBottom style={{textAlign:'justify'}}>
                                    Recharge for this game is currently unavailble. <br />Please try again after sometime.<br /> We appriciate your pateince.
                                </Typography>
                            </ GamerPaper>
                        </Grid>
                        </Grid>
                    </>}
                </Box>
            </Container>

            <ProfessionalNavbar />
        </>
    );
}